import React from "react"
import Layout from '../components/layout/layout';
import { injectIntl } from "gatsby-plugin-intl";
import { 
  ContactSection,
  Footer,
  Header,
  ISwatImageSection,
  MobileAlarmAppHero,
  SeeMoreSection,
  SEO,
  ServiceDescriptionSection
} from "../components";

const MobileAlarmAppPage = ({intl}) => {

  return(
    <Layout>
      <SEO title="Mobile Alarm App" lang={intl.locale} description={intl.formatMessage({ id: `mobile-alarm-app.site.description`})} />
      <Header isNotTransparent />
      <MobileAlarmAppHero isSecondaryHero />
      <ServiceDescriptionSection
        page={'mobile-alarm-app'}
      />
      <ISwatImageSection/>
      <ContactSection page={'mobile-alarm-app'}/>
      <SeeMoreSection page={'mobile-alarm-app'}/>
      <Footer/>
    </Layout>
  )
}

export default injectIntl(MobileAlarmAppPage)
